import { useTranslation } from 'next-i18next'

import React from 'react'
import { HalfStar, Star } from './ReviewStar'
import Link from '../overwrites/Link'

type Props = {
  stars: number
  numberOfReviews: number
  name: string
  isTile?: boolean
  productLink?: string
}

const Reviews = ({ stars, numberOfReviews, isTile, productLink }: Props) => {
  const { t } = useTranslation('common')
  const totalStars = 5
  const roundedStars = Math.round(stars * 2) / 2
  const fullStars = Math.floor(roundedStars)
  const halfStar = roundedStars % 1 !== 0 ? 1 : 0
  const emptyStars = totalStars - fullStars - halfStar
  const flooredReviews =
    numberOfReviews > 10
      ? Math.floor(numberOfReviews / 10) * 10 + '+'
      : numberOfReviews

  if (!stars) {
    return null
  }
  if (numberOfReviews === 0) {
    stars = 0
  }

  const starsClasses = isTile ? 'text-xs' : 'text-sm'
  const textClasses = isTile ? 'text-xs' : 'text-sm'

  const reviewsToReturn = (
    <div className="flex items-center">
      <span
        className={`${starsClasses} whitespace-nowrap font-medium`}
        style={{ color: '#ebb305' }}
      >
        {stars.toFixed(1)}
      </span>
      <span
        className="ml-1 flex whitespace-nowrap"
        style={{ marginBottom: '2px' }}
      >
        {[...Array(fullStars)].map((_, index) => (
          <Star key={index} fill="#ebb305" /> // Full star
        ))}
        {halfStar > 0 && <HalfStar key="half-star" />}
        {[...Array(emptyStars)].map((_, index) => (
          <Star key={`empty-${index}`} fill="none" /> // Empty star
        ))}
      </span>
      {!isNaN(numberOfReviews || 0) && (
        <span className={`${textClasses} ml-2 text-neutral whitespace-nowrap`}>
          {flooredReviews}{' '}
          <span className="xs:inline hidden">{isTile ? '' : t('reviews')}</span>
        </span>
      )}
    </div>
  )

  return !isTile && productLink ? (
    <Link href={productLink} passHref target="_blank">
      {reviewsToReturn}
    </Link>
  ) : (
    reviewsToReturn
  )
}

export default Reviews
