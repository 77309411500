import React from 'react'
import { IdealImage } from './shared/IdealImage'
import {
  ensurePartnerIdAndCampaign,
  replaceWildcards,
} from '../lib/helper/helper'
import Link from './overwrites/Link'
import { useTranslation } from 'next-i18next'

type Props = {
  coverImage?: any
  h1?: string
  h2?: string
  name?: string
  nameDescription?: string
  isLandingPage?: boolean
  wildcards?: Wildcards
  bannerDestination?: AnyDestination
  product_link?: string
}

export function PageBanner({
  coverImage,
  h1,
  h2,
  name,
  nameDescription,
  isLandingPage,
  wildcards,
  product_link,
}: Props) {
  const { t } = useTranslation('common')
  const isExternal = (href: string) =>
    !href.startsWith('/') && !href.startsWith('#')
  // const [translatedButton, setTranslatedButton] = useState('')

  // useEffect(() => {
  //   setTranslatedButton(t('discover-all-activities'))
  // }, [t])

  console.log(product_link)

  const renderBannerContent = () => (
    <div className="mx-3 xs:mx-8 flex md:flex-row flex-col md:items-end items-stretch justify-between gap-1 gap-3 z-30 mb-6 xs:mb-8 absolute bottom-0 left-0 right-0">
      <div className="flex flex-col items-start text-white">
        <h1 className="text-left xs:text-6xl text-3xl font-medium font-rubik uppercase">
          {replaceWildcards(isLandingPage ? h1 : name, wildcards)}
        </h1>
        <h2 className="text-left xs:text-2xl text-2xl mt-2">
          {replaceWildcards(isLandingPage ? h2 : nameDescription, wildcards)}
        </h2>
      </div>
      {product_link && (
        <Link
          className="hidden xs:block"
          href={ensurePartnerIdAndCampaign(product_link)}
          target={isExternal(product_link) ? '_blank' : undefined}
          rel={isExternal(product_link) ? 'noopener noreferrer' : undefined}
        >
          <div className="w-full xs:w-auto bg-white text-black px-6 py-3 rounded-md text-lg font-bold text-center uppercase hover:bg-primary hover:text-white transition-colors">
            {t('discover-all-activities')}
          </div>
        </Link>
      )}
    </div>
  )

  const heightClasses = isLandingPage ? 'h-120' : 'xs:h-96 h-80'

  return (
    <>
      <div className={`relative ${heightClasses} xs:mb-10 overflow-hidden`}>
        <div className="absolute inset-0 z-0 xs:rounded-md overflow-hidden">
          <IdealImage
            title={replaceWildcards((name || h1) + ' - SIGHT2SIGHT', wildcards)}
            alt={replaceWildcards((name || h1) + ' - SIGHT2SIGHT', wildcards)}
            image={coverImage}
            priority={true}
          />
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-1/2 z-20 xs:rounded-md overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-t xs:from-black/80 from-black/90 to-transparent"></div>
        </div>
        <div />
        {renderBannerContent()}
      </div>
      {!isLandingPage && h1 && (
        <div className="uppercase font-medium text-2xl xs:text-3xl mt-2 mb-3 mx-3 xs:mx-0">
          <b>{replaceWildcards(h1, wildcards)}</b>
        </div>
      )}
    </>
  )
}
