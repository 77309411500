import React, { lazy } from 'react'
import 'tailwindcss/tailwind.css'
import '../public/assets/custom.css'
import '../public/fontawesome/css/regular.min.css'
import '../public/fontawesome/css/solid.min.css'
import '../public/fontawesome/css/duotone.min.css'
import '../public/fontawesome/css/fontawesome.min.css'
import { AppProps } from 'next/app'
import { Header } from '../components/header/Header'
import { appWithTranslation } from 'next-i18next'
import { Breadcrumbs } from '../components/shared/Breadcrumbs'
import { AppWidthContainer } from '../components/AppWidthContainer'
import { LocalStorageProvider } from '../components/LocalStorageProvider'
import Footer from '../components/footer/Footer'
import { Analytics } from '@vercel/analytics/react'

export interface SharedPageProps {
  draftMode: boolean
  token: string
}

const PreviewProvider = lazy(() => import('../components/PreviewProvider'))

function App({ Component, pageProps }: AppProps<SharedPageProps>) {
  const { draftMode, token } = pageProps

  return (
    <LocalStorageProvider>
      <Header />
      <AppWidthContainer>
        <Breadcrumbs />
        {draftMode ? (
          <PreviewProvider token={token}>
            <Component {...pageProps} />
          </PreviewProvider>
        ) : (
          <Component {...pageProps} />
        )}
      </AppWidthContainer>
      <Footer />
      <div className="h-24" />
      <Analytics />
    </LocalStorageProvider>
  )
}

export default appWithTranslation(App)
