import { PortableText } from '@portabletext/react'
import React from 'react'
import { BigReviews } from './BigReviews'
import { PORTABLE_COMPONENTS } from '../../lib/helper/portableTextHelpers'

type Props = {
  description: RichText[]
  stars?: number
  numberOfReviews?: number
  nameLocation?: string
  nameEntity?: string
  withBorder?: boolean
  noTopMargin?: boolean
  suffix?: React.ReactNode
}

export function Description({
  description,
  withBorder,
  suffix,
  noTopMargin,
  ...props
}: Props) {
  const classes = `${withBorder ? 'gyg-widget-copy' : ''} mx-3 xs:mx-0 ${noTopMargin ? 'mb-10' : 'my-10'} xs:my-10`
  return (
    <div className={`xs:my-10 ${classes} portableText`}>
      <PortableText value={description} components={PORTABLE_COMPONENTS} />
      <BigReviews {...props} />
      <div className="w-full xs:flex xs:justify-center">{suffix}</div>
    </div>
  )
}
