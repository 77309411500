import { groq } from 'next-sanity'

export const getAllCountriesQuery = (
  locale: AppLocale,
  offset?: number,
) => groq`
  *[_type == "country" && defined(slug.${locale}.current)]
  | order(coalesce(priority, 0) desc, coalesce(number_activities.${locale}, 0) desc)
  {
   ${getReducedCountryFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

const getReducedCountryFields = (locale: AppLocale) => `
  _id,
  _type,
  _updatedAt,
  "name": name.${locale},
  "nameDescription": name_description.${locale},
  slug,
  coverImage,
  priority,
  "number_activities": number_activities.${locale},
`

export const getCountryBySlugQuery = (
  locale: AppLocale,
  countrySlug: string,
) => groq`
  *[_type == "country" && slug.${locale}.current == "${countrySlug}"][0] {
    _id,
    _type,
    "name": name.${locale},
    "nameDescription": name_description.${locale},
    "h1": h1.${locale},
    slug,
    coverImage,
    "highlights": highlights.${locale},
    "description_1": description_1.${locale},
    "description_2": description_2.${locale},
    "description_3": description_3.${locale},
    reviews,
    "faq": faq.${locale},
    "meta_title": meta_title.${locale},
    "meta_description": meta_description.${locale},
    "number_activities": number_activities.${locale},
    number_locations,
    "dynamicFooter": dynamic_footer.${locale},
    "product_link": product_link,
  }

`
