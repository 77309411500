import { apiVersion, dataset, projectId, useCdn } from '../lib/sanity.api'
import { createClient, type SanityClient } from 'next-sanity'
import { getSettingsQuery } from './queries/settings.queries'
import {
  getAllCountriesQuery,
  getCountryBySlugQuery,
} from './queries/country.queries'
import {
  getAllCategoriesInLocationQuery,
  getAllCategoriesInRegionQuery,
  getAllCategoriesInStateQuery,
  getAllCategoriesQuery,
  getCategoryBySlugQuery,
  getOtherCategoriesInLocationQuery,
} from './queries/category.queries'
import {
  getAllBookmarkedProductsQuery,
  getAllLastViewedProductsQuery,
  getAllProductsInCountryQuery,
  getAllProductsInCategoryQuery,
  getAllProductsInLocationQuery,
  getAllProductsQuery,
  getProductBySlugQuery,
  getSimilarProductsQuery,
  getAllProductsCountQuery,
} from './queries/product.queries'
import {
  getAllRegionsInCountryQuery,
  getAllRegionsInStateQuery,
  getAllRegionsQuery,
  getRegionBySlugQuery,
} from './queries/region.queries'
import {
  getAllStatesInCountryQuery,
  getAllStatesQuery,
  getStateBySlugQuery,
} from './queries/state.queries'
import {
  getAllCitiesInCountryByTypeQuery,
  getAllCitiesInRegionQuery,
  getAllCitiesInStateQuery,
  getAllCitiesQuery,
  getCityBySlugQuery,
} from './queries/city.queries'
import { getEntityListsQuery } from './queries/entity_lists.queries'

export function getClient(preview?: { token: string }): SanityClient {
  const client = createClient({
    projectId,
    dataset,
    apiVersion,
    useCdn,
    perspective: 'published',
  })
  if (preview) {
    if (!preview.token) {
      throw new Error('You must provide a token to preview drafts')
    }
    return client.withConfig({
      token: preview.token,
      useCdn: false,
      ignoreBrowserTokenWarning: true,
      perspective: 'previewDrafts',
    })
  }
  return client
}

export const getSanityImageConfig = () => getClient()

// Settings
export async function getSettings(locale: AppLocale, client: SanityClient) {
  return client.fetch(getSettingsQuery(locale))
}

// Entity Lists
export async function getEntityLists(locale: AppLocale, client: SanityClient) {
  return client.fetch(getEntityListsQuery(locale))
}

// Country
export async function getAllCountries(
  locale: AppLocale,
  client: SanityClient,
  offset?: number,
) {
  return client.fetch<Country[]>(getAllCountriesQuery(locale, offset))
}
export async function getCountryBySlug(
  locale: AppLocale,
  client: SanityClient,
  countrySlug: string,
) {
  return client.fetch<Country>(getCountryBySlugQuery(locale, countrySlug))
}

// City
export async function getAllCities(
  locale: AppLocale,
  client: SanityClient,
  offset?: number,
) {
  return client.fetch<City[]>(getAllCitiesQuery(locale, offset))
}
export function getGetAllCitiesInCountry(countryId: string) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<MajorLocation[]>(
      getAllCitiesInCountryByTypeQuery(locale, countryId, offset),
    )
  }
}
export function getGetAllCitiesInState(stateId: string) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<City[]>(
      getAllCitiesInStateQuery(locale, stateId, offset),
    )
  }
}
export function getGetAllCitiesInRegion(regionId: string) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<City[]>(
      getAllCitiesInRegionQuery(locale, regionId, offset),
    )
  }
}
export async function getCityBySlug(
  locale: AppLocale,
  client: SanityClient,
  citySlug: string,
) {
  return client.fetch<MajorLocation>(getCityBySlugQuery(locale, citySlug))
}

// State
export function getGetAllStatesInCountry(countryId: string) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<State[]>(
      getAllStatesInCountryQuery(locale, countryId, offset),
    )
  }
}
export async function getStateBySlug(
  locale: AppLocale,
  client: SanityClient,
  stateSlug: string,
) {
  return client.fetch<State>(getStateBySlugQuery(locale, stateSlug))
}
export async function getAllStates(
  locale: AppLocale,
  client: SanityClient,
  offset?: number,
) {
  return client.fetch<State[]>(getAllStatesQuery(locale, offset))
}

// Region
export function getGetAllRegionsInCountry(countryId: string) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<Region[]>(
      getAllRegionsInCountryQuery(locale, countryId, offset),
    )
  }
}
export function getGetAllRegionsInState(stateId: string) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<Region[]>(
      getAllRegionsInStateQuery(locale, stateId, offset),
    )
  }
}
export async function getRegionBySlug(
  locale: AppLocale,
  client: SanityClient,
  regionSlug: string,
) {
  return client.fetch<Region>(getRegionBySlugQuery(locale, regionSlug))
}
export async function getAllRegions(
  locale: AppLocale,
  client: SanityClient,
  offset?: number,
) {
  return client.fetch<Region[]>(getAllRegionsQuery(locale, offset))
}

// Category
export async function getAllCategories(
  locale: AppLocale,
  client: SanityClient,
  offset?: number,
) {
  return client.fetch<Category[]>(getAllCategoriesQuery(locale, offset))
}
export function getGetAllCategoriesInLocation(locationId: string) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<Category[]>(
      getAllCategoriesInLocationQuery(locale, locationId, offset),
    )
  }
}
export function getGetAllCategoriesInState(stateId: string) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<Category[]>(
      getAllCategoriesInStateQuery(locale, stateId, offset),
    )
  }
}
export function getGetAllCategoriesInRegion(regionId: string) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<Category[]>(
      getAllCategoriesInRegionQuery(locale, regionId, offset),
    )
  }
}
export async function getCategoryBySlug(
  locale: AppLocale,
  client: SanityClient,
  categorySlug: string,
) {
  return client.fetch<Category>(getCategoryBySlugQuery(locale, categorySlug))
}
export async function getOtherCategoriesInLocation(
  locale: AppLocale,
  client: SanityClient,
  locationId: string,
  ignoreId?: string,
) {
  return client.fetch<(Category & { name_de: string })[]>(
    getOtherCategoriesInLocationQuery(locale, locationId, ignoreId),
  )
}

// Product
export async function getAllProducts(
  locale: AppLocale,
  client: SanityClient,
  offset?: number,
  packageSize?: number,
) {
  return client.fetch<ReducedProduct[]>(
    getAllProductsQuery(locale, offset, packageSize),
  )
}
export async function getAllProductsCount(
  locale: AppLocale,
  client: SanityClient,
) {
  return client.fetch<number>(getAllProductsCountQuery(locale))
}
export function getGetAllProductsInLocation(
  locationType: 'city' | 'state' | 'region',
  locationId: string,
  ignoreId?: string,
) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    const locationTypeToUse =
      locationType === 'city' ? 'location' : locationType
    return client.fetch<Product[]>(
      getAllProductsInLocationQuery(
        locale,
        locationTypeToUse,
        locationId,
        ignoreId,
        offset,
      ),
    )
  }
}
export async function getProductBySlug(
  locale: AppLocale,
  client: SanityClient,
  productSlug: string,
) {
  return client.fetch<Product>(getProductBySlugQuery(locale, productSlug))
}
export function getGetSimilarProducts(
  categoryIds: string[],
  ignoreId?: string,
) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<Product[]>(
      getSimilarProductsQuery(locale, categoryIds, ignoreId, offset),
    )
  }
}
export function getGetAllProductsInCountry(countryId: string) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<Product[]>(
      getAllProductsInCountryQuery(locale, countryId, offset),
    )
  }
}
export function getGetAllProductsInCategory(categoryId: string) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    return client.fetch<ReducedProduct[]>(
      getAllProductsInCategoryQuery(locale, categoryId, offset),
    )
  }
}

// bookmarks
export function getGetAllBookmarkedProducts(bookmarkedProductIds: string[]) {
  return async function (locale: AppLocale, client: SanityClient) {
    return client.fetch<Product[]>(
      getAllBookmarkedProductsQuery(locale, bookmarkedProductIds),
    )
  }
}

// lastViewed
export function getGetAllLastViewedProducts(lastViewedProductIds: string[]) {
  return async function (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) {
    const lastViewedProducts = await client.fetch<Product[]>(
      getAllLastViewedProductsQuery(locale, lastViewedProductIds, offset),
    )
    const sorted = lastViewedProductIds
      .map((id) => {
        return lastViewedProducts.find((product) => product._id === id)
      })
      .filter(Boolean)
    return sorted
  }
}
