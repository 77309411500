import { groq } from 'next-sanity'

export const getAllStatesInCountryQuery = (
  locale: AppLocale,
  countryId: string,
  offset?: number,
) => groq`
  *[_type == "state" && country->_id == "${countryId}" && defined(slug.${locale}.current)]
  | order(coalesce(number_activities.${locale}, 0) desc)
  {
   ${getReducedStateFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

export const getAllStatesQuery = (locale: AppLocale, offset?: number) => groq`
  *[_type == "state" && defined(slug.${locale}.current)]
  | order(coalesce(number_activities.${locale}, 0) desc)
  {
    ${getReducedStateFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

const getReducedStateFields = (locale: AppLocale) => ` 
  _id,
  _type,
  _updatedAt,
  "name": name.${locale},
  slug,
  coverImage,
  priority,
  "number_activities": number_activities.${locale},
  number_locations
`

export const getStateBySlugQuery = (
  locale: AppLocale,
  stateSlug: string,
) => groq`
  *[_type == "state" && slug.${locale}.current == "${stateSlug}"][0] {
    _id,
    _type,
    "name": name.${locale},
    "nameDescription": name_description.${locale},
    "h1": h1.${locale},
    slug,
    coverImage,
    "highlights": highlights.${locale},
    "description_1": description_1.${locale},
    "description_2": description_2.${locale},
    "description_3": description_3.${locale},
    "faq": faq.${locale},
    "meta_title": meta_title.${locale},
    "meta_description": meta_description.${locale},
    reviews,
    "city": city->{
      _id,
      "name": name.${locale},
      "slug": slug.${locale}.current
    },
    "number_activities": number_activities.${locale},
    number_locations,
    product_link
  }
`
