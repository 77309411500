import { useTranslation } from 'next-i18next'
import React, { useRef, useState } from 'react'

type Props = {
  faqs: FAQ[]
  nameEntity?: string
}

export function FAQ({ faqs, nameEntity }: Props) {
  const { t } = useTranslation('common')
  const half = Math.ceil(faqs.length / 2)
  const leftFaqs = faqs.slice(0, half)
  const rightFaqs = faqs.slice(half)

  return (
    <div className="gyg-widget-copy mx-3 xs:mx-0 my-10">
      <h2 className="text-2xl font-medium text-basis xs:ml-0 mb-4 text-left">
        {t('faq-title', { entity: nameEntity || 'FAQ' })}
      </h2>
      <div className="relative flex flex-wrap md:flex-nowrap gap-x-12">
        <div className="flex-1 z-10">
          {leftFaqs.map((faq, index) => (
            <FAQItem key={index} faq={faq} />
          ))}
        </div>
        <div
          className="absolute inset-0 m-auto hidden md:block"
          style={{ height: '100%' }}
        >
          <div className="w-px bg-gray-300 mx-auto h-full"></div>
        </div>
        <div className="flex-1 z-10">
          {rightFaqs.map((faq, index) => (
            <FAQItem key={index} faq={faq} />
          ))}
        </div>
      </div>
    </div>
  )
}

const FAQItem = ({ faq }: { faq: FAQ }) => {
  const [isOpen, setIsOpen] = useState(false)
  const contentRef = useRef(null)

  // Function to toggle FAQ item
  const toggleFAQ = () => {
    const content = contentRef.current

    if (isOpen) {
      // If the item is open, close it smoothly
      const sectionHeight = content.scrollHeight
      const elementTransition = content.style.transition
      content.style.transition = ''

      requestAnimationFrame(() => {
        content.style.height = sectionHeight + 'px'
        content.style.transition = elementTransition

        requestAnimationFrame(() => {
          content.style.height = 0 + 'px'
        })
      })
    } else {
      // If the item is closed, open it smoothly
      content.style.height = content.scrollHeight + 'px'
      content.addEventListener(
        'transitionend',
        () => {
          content.style.height = null
        },
        { once: true },
      )
    }

    setIsOpen(!isOpen)
  }

  return (
    <div className="py-2 cursor-pointer" onClick={toggleFAQ}>
      <div className="flex items-baseline justify-between gap-4">
        <h3 className="font-medium text-sm flex items-baseline justify-between">
          <span className="text-primary fad fa-circle-question min-w-6" />
          {faq.faq_question}
        </h3>
        {isOpen ? (
          <span className="fad fa-xmark" />
        ) : (
          <span className="fad fa-plus" />
        )}
      </div>
      <div
        ref={contentRef}
        className="overflow-hidden transition-height duration-200 ease-in-out"
        style={{ height: 0 }}
      >
        <p className="pt-2 text-sm ml-6 mr-4">{faq.faq_answer}</p>
      </div>
    </div>
  )
}
