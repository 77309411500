import { groq } from 'next-sanity'

export const getAllCategoriesQuery = (
  locale: AppLocale,
  offset?: number,
) => groq`
  *[_type == "category" && defined(slug.${locale}.current)]
  {
    ${getReducedCategoryFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

export const getAllCategoriesInLocationQuery = (
  locale: AppLocale,
  locationId: string,
  offset?: number,
) => groq`
  *[_type == "category" && defined(slug.${locale}.current) && location->_id == "${locationId}"]
  | order(coalesce(priority, 0) desc, coalesce(number_activities.${locale}, 0) desc)
  {
    ${getReducedCategoryFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

export const getAllCategoriesInStateQuery = (
  locale: AppLocale,
  stateId: string,
  offset?: number,
) => groq`
  *[_type == "category" && defined(slug.${locale}.current) && location->state->_id == "${stateId}"]
  | order(coalesce(priority, 0) desc, coalesce(number_activities.${locale}, 0) desc)
  {
    ${getReducedCategoryFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`
export const getAllCategoriesInRegionQuery = (
  locale: AppLocale,
  regionId: string,
  offset?: number,
) => groq`
  *[_type == "category" && defined(slug.${locale}.current) &&"${regionId}" in location->region[]._ref]
  | order(coalesce(priority, 0) desc, coalesce(number_activities.${locale}, 0) desc)
  {
    ${getReducedCategoryFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

export const getOtherCategoriesInLocationQuery = (
  locale: AppLocale,
  locationId: string,
  ignoreId?: string,
) => groq`
  *[_type == "category" && defined(slug.${locale}.current) && _id != "${ignoreId}" && location->_id == "${locationId}"]
  | order(coalesce(priority, 0) desc, coalesce(number_activities.${locale}, 0) desc)
  {
    "name_de": name.de,
    ${getReducedCategoryFields(locale)}
  }
`

const getReducedCategoryFields = (locale: AppLocale) => `
  _id,
  _type,
  _updatedAt,
  "name": name.${locale},
  slug,
  coverImage,
  fa_icon,
  "location": location->{
    slug,
    "name": name.${locale},
    _type,
  },
  priority,
  "number_activities": number_activities.${locale},
`

export const getCategoryBySlugQuery = (
  locale: AppLocale,
  categorySlug: string,
) => groq`
  *[_type == "category" && slug.${locale}.current == "${categorySlug}"][0] {
    _id,
    _type,
    "name": name.${locale},
    "nameDescription": name_description.${locale},
    "h1": h1.${locale},
    slug,
    coverImage,
    reviews,
    "highlights": highlights.${locale},
    "description_1": description_1.${locale},
    "description_2": description_2.${locale},
    "description_3": description_3.${locale},
    "faq": faq.${locale},
    "meta_title": meta_title.${locale},
    "meta_description": meta_description.${locale},
    "location": location->{
      _id,
      _type,
      "name": name.${locale},
      slug,
      fa_icon,
      "number_activities": number_activities.${locale},
      "travelPlanWidget": travel_plan_widget-> {
        _type,
        "text_above": text_above.${locale},
        "title": title.${locale},
        "sub_title": sub_title.${locale},
        global_link,
        image
      },
    },
    "number_activities": number_activities.${locale},
  }
`
