import React, { useState, useEffect, useRef, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { getClient } from '../../lib/sanity.client'
import { useOutsideAlerter } from '../../lib/hooks/useOutsideAlerter'
import { getSearchQuery } from '../../lib/queries/search.query'
import { SearchInput } from './SearchInput'
import { SearchResults } from './SearchResults'
import { getHref, limitAndSortResults } from '../../lib/helper/helper'
import { useIsMobile } from '../../lib/hooks/useIsMobile'
import { useScrollPast } from '../../lib/hooks/useScrollPast'
import { LoadingIndicator } from './LoadingIndicator'

type Props = {
  searchFocused: boolean
  setSearchFocused: (value: boolean) => void
}

export function Search({ searchFocused, setSearchFocused }: Props) {
  const { i18n } = useTranslation('common')
  const { language } = i18n as { language: AppLocale }
  const router = useRouter()
  const isNotRoot = router.pathname !== '/'
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1)
  const searchContainerRef = useRef<HTMLDivElement>(null)
  const searchResultsRef = useRef<HTMLDivElement>(null)
  const scrolledPastBanner = useScrollPast(370)
  const scrolledPastHeader = useScrollPast(56)
  const isMobile = useIsMobile(768)
  const client = getClient()

  useOutsideAlerter([searchResultsRef], () => {
    !isMobile && setSearchFocused(false)
    !isMobile && setIsDropdownVisible(false)
  })

  const search = async (query: string) => {
    if (!query) {
      setSearchResults([])
      return
    }
    try {
      const results = await client.fetch(
        getSearchQuery(language, replaceUmlauts(query)),
      )
      setSearchResults(
        limitAndSortResults(
          results.map((r: any) => ({
            ...r,
            location: r.location1 || r.location2,
          })),
        ),
      )
    } catch (error) {
      console.error('Error searching:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const replaceUmlauts = (query: string) => {
    return query.normalize('NFD')
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(search, 400), [language])

  useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value
    setIsLoading(true)
    setSearchTerm(query)
    debouncedSearch(query)
    setSearchFocused(true)
    setIsDropdownVisible(!!query)
    setHighlightedIndex(-1)
  }

  const navigateToSelectedResult = useCallback(() => {
    if (highlightedIndex >= 0 && highlightedIndex < searchResults.length) {
      const selectedItem = searchResults[highlightedIndex]
      const href = getHref(selectedItem, language)
      router.push(href)
    }
  }, [highlightedIndex, searchResults, language, router])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault()
        setHighlightedIndex((prev) =>
          Math.min(prev + 1, searchResults.length - 1),
        )
        break
      case 'ArrowUp':
        event.preventDefault()
        setHighlightedIndex((prev) => Math.max(prev - 1, 0))
        break
      case 'Enter':
        if (highlightedIndex > -1) {
          event.preventDefault()
          navigateToSelectedResult()
          setIsDropdownVisible(false)
        }
        break
      case 'Escape':
        setIsDropdownVisible(false)
        break
    }
  }

  const onClickSearchInput = () => {
    setSearchFocused(true)
    if (!isMobile && searchTerm.length > 0) setIsDropdownVisible(true)
  }

  useEffect(() => {
    const updateBodyClass = () => {
      const className = 'search-results-visible'
      if (isMobile && isDropdownVisible) {
        document.body.classList.add(className)
      } else {
        document.body.classList.remove(className)
      }
    }

    updateBodyClass()
    return () => document.body.classList.remove('search-results-visible')
  }, [isMobile, isDropdownVisible])

  const searchFullWidth = isMobile && (searchFocused || scrolledPastBanner)
  if (isMobile === null || (isMobile && isNotRoot && !searchFullWidth))
    return null

  return (
    <div
      className={`${
        searchFullWidth
          ? 'fixed top-0 mt-0 left-0 right-0 w-full py-1 bg-white'
          : scrolledPastHeader
            ? 'fixed top-0 w-full left-0 right-0 xs:mt-6'
            : 'absolute top-14 xs:top-24 w-full left-0 right-0 mt-2'
      } md:static z-50 md:mt-0 xs:px-6`}
    >
      <div
        ref={searchContainerRef}
        className="relative h-12 flex flex-col items-stretch justify-stretch my-1 md:my-0"
      >
        <SearchInput
          searchTerm={searchTerm}
          onSearchTermChange={handleSearchChange}
          onSearchKeyDown={handleKeyDown}
          onClearSearch={() => {
            setSearchTerm('')
            setIsDropdownVisible(false)
            setSearchFocused(false)
          }}
          searchFocused={searchFocused}
          onClick={onClickSearchInput}
        />
        {isLoading && !!searchTerm && <LoadingIndicator />}
        {!isLoading && searchTerm && isDropdownVisible && (
          <SearchResults
            results={searchResults}
            highlightedIndex={highlightedIndex}
            setHighlightedIndex={setHighlightedIndex}
            onResultSelect={() => {
              setSearchFocused(false)
              setIsDropdownVisible(false)
            }}
            searchResultsRef={searchResultsRef}
            searchFocused={searchFocused}
          />
        )}
      </div>
    </div>
  )
}
