import { useState, useEffect } from 'react'

export function useScrollPast(offset: number) {
  const [scrolledPast, setScrolledPast] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setScrolledPast(window.pageYOffset > offset)
    }
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => window.removeEventListener('scroll', handleScroll)
  }, [offset])

  return scrolledPast
}
